.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 1.3rem;
}

.nav__item a {
  font-weight: 500;
  font-size: 1.1rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nave__item a:hover {
  color: #17bf9c;
}

.nav_right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
}

.mobile__menu {
  display: none;
}

.logo img {
  font-size: 3em;
  width: 150px;
}

.xs-img {
  width: 300px;
}

.logo {
  font-size: 0.9em;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1eb2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 150px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }

  .nav__right {
    display: none;
  }

  .active_menu {
    display: block;
  }

  .logo img {
    width: 50%;
  }
}
