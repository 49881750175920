.testimonial__content h2 {
    font-weight: 600;
}

@media only screen and (max-width: 991px) {
 .testimonial__content div {
    width: 300px;
 }

 .cont {
    margin-top: -80px;
 }
}