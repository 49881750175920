.counter {
    font-size: 2rem;
    font-weight: 600;
    color: #0a2b1e;
  }

  .counter__title {
    font-size: 1rem;
    color: #0a2b1e;
    font-weight: 500;
  }

  .about__content {
    padding-left: 50px;
    /* paddint-top: 80px; */
  }

  .about__img img {
    border-radius: 15px;
  }

  .about__content h2 {
    font-weight: 600;
  }


  @media only screen and (max-width: 991px) {
    .about {
      margin-top: -70px;
    }

    .about__content {
      margin-top: 15px;
      padding-left: 0px;
    }

  }