.hero_content h2 {
    font-weight: 600;
    line-height: 55px;
}

.hero_content {
    padding-top: 1px;
}

@media only screen and (max-width: 991px) {
    .hero_content {
        padding-top: 40px;
    }

    .hero_content h2 {
        font-weight: 600;
        line-height: 32px;
        /* hidden; */
    }
    /* .nav__menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0a2b1eb2;
        z-index: 99;
        display: none;
    }

    .nav__list {
        position: absolute;
        background: #fff;
        top: 0;
        right: 0;
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 999;
        padding-top: 80px;
    }

    .mobile__menu {
        display: block;
    }

    .nav__right {
        display: none;
    }

    .active_menu {
        display: block;
    } */
}

/* .search {
    width: 60%;
    border: 1px solid #17bf9d44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    margin-top: 20px;
}

.search input {
    border: none;
    outline: none;
    padding: 7px 10%;
} */