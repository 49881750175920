/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* html {
  font-family: sans-serif;
} */

/* .body {
  background: linear-gradient(to right, #2c5364, #203a43);
} */

.App {
  /* min-height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand {
  width: 15rem;
}

.card {
   min-height: 30vh;
   width: 20em;
  background: #fff;
  padding: 3rem 2rem;
}

.card p {
  padding-top: 1rem;
  line-height: 150%;
  color: #fff;
}

.single__feature h2 i {
  font-size: 2.5rem;
}

.single__feature h6 {
  font-weight: 600;
}

@media only screen and (max-width: 991px) {
  .feature {
    margin-top: -70px;
    pading-left: 0px;
  }
  .card {
    min-height: 25vh;
    background: #fff;
    padding: 1rem 1rem;
  }
  .expand {
    width: 12rem;
  }

  .card p {
    padding-top: 1px;
  }
}
