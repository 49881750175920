.footer {
    /* background: rgba(226, 248, 248, 0.664); */
    background: rgba(241, 231, 254);
  }

  .follows span a {
    text-decoration: none;
  }

  .follows span {
    margin-right: 15px;
  }

  .link__list .link__item {
    background: transparent;
  }

  .link__list a {
    text-decoration: none;
    color: #0a2b1e;
    font-weight: 500;
  }