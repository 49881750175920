.newsletter {
    background: #17bf9e;
    margin-top: -70px;
    padding: 60px;
    border-radius: 15px;
}

.newsletter h2 {
    color: #fff;
}

.subscribe input {
    border: none;
    outline: none;
}

.subscribe {
    padding: 7px 10px;
    background: #fff;
    width: 45%;
    margin: auto;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width: 991px) {
    .subscribe {
        width: 100%;
        padding: 0px 0px;
    }
}